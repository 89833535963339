export const initialText = `# Markdown2PDF 

A tool to convert markdown files to pdf

## How to use md2pdf?
1. Click button choose \`.md\` file.
2. Edit in editor (left panel).
3. Click **Transform**!
4. Switch 'Destination' to **Save as PDF**.
4. **Chrome recommended**

## Project Github Address
- Github: [@md2pdf](https://github.com/realdennis/md2pdf)

## Wechat Official Accounts

![欢迎关注微信公众号「运维咖啡吧」](/static/qrcode.jpg)
`;
